body {
  color: #ffffff;
  margin: 0;
  font-family: "courier", "monospace";
  background-color: #535353;
}

@media (orientation: portrait) and (max-width: 850px) {
  body,.navbutton,.dropbtn{
  font-size: 2em;
  }
  nav{
    font-size: 2.25em;
  }
  #page.small{
    font-size:1.5em;
  }
}

/* NAVBAR STUFF */

#navbar{
  background-color: #0C0C0C;
  color: #ffffff;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 1000px;
  min-width: 800px;
  font-size: 0px;
  height: 102px;
}

nav{
  background-color: #000000;
	color: #ffffff;
  font-family: 'Roboto', sans-serif;
  margin: auto;
  font-size: 1.25em;
  max-width: 1000px;
  //min-width: 800px;
}

.navbutton{
  font-family: inherit;
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: black;
  color: inherit;
  border: none;
  cursor: pointer;
}

.navbutton:hover{
  background-color: #111111;
}

nav span{
  display: inline-block;
}

#leftlinks{
  padding-left: 40px;
}

#rightlinks{
  float: right;
  padding-right: 40px;
  padding-top: 5px;
}

@media (orientation:portrait) and (max-width: 850px) {
  #rightlinks {
    clear: both;
    float: none;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 5px 0;
  }
} 

/* Dropdowns */

/* Style The Dropdown Button */
.dropbtn {
  font-family: inherit;
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
  color: inherit;
  background-color: black;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111111;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #555555
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #111111;
}

/* Cast page stylings */

.cast-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.cast-box {
  width: 400px;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  background-color: #282828;
}
.cast-content {
  display: flex;
  flex-grow: 1;
}
.cast-image-container {
  width: 160px;
  height: 160px;
  position: relative;
  margin-right: 15px;
}
.cast-image {
  position: absolute;
  /* Default positioning */
  top: 0;
  left: 0;
}
.cast-image-transition {
  transition: opacity 0.7s ease;
}
.cast-image-hover {
  position: absolute;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.cast-image-container:hover .cast-image-transition {
  opacity: 0;
}
.cast-image-container:hover .cast-image-hover {
  opacity: 1;
}
.cast-text {
  flex: 1;
}
.cast-name {
  font-weight: bold;
  margin: 0px;
}
.cast-description {
  font-size: 0.9em;
  margin-top: 10px;
}
.cast-description-image {
  max-width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
  margin-bottom: 5px;
  margin-left: 10px;
}
.cast-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  font-family: monospace;
  border-top: 1px solid #eee;
  padding-top: 10px;
  height: 20px; /* Set this to your desired height */
  margin-top: auto; /* This pushes the info bar to the bottom */
}
.cast-info-left {
  text-align: left;
}
.cast-info-right {
  text-align: right;
}

.chapter-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.chapter-link {
  padding: 10px 20px;
  margin: 5px 5px;
  border: 2px solid #ccc;
  background-color: #000000;
  color: #ccc;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.chapter-link:hover {
  background-color: #e0e0e0;
  color:black;
}
.chapter-link.active {
  background-color: #4CAF50;
  color: white;
  border-color: #45a049;
}


/* PINK-BASED COLOUR-SWITCHING */

/* body */

body.pink{
	background: #644865; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(#644865, #281e29); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#644865, #281e29); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#644865, #281e29); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#644865, #281e29); /* Standard syntax */
}

body.pink_1{
  background: #503d51; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(#503d51, #282129); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#503d51, #282129); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#503d51, #282129); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#503d51, #282129); /* Standard syntax */
}

body.pink_2{
  background: #3c333c; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(#3c333c, #282528); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#3c333c, #282528); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#3c333c, #282528); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#3c333c, #282528); /* Standard syntax */
}

/* Secret Stuff! */

.secret_00 {
	background-image: url("/chiasmata/images/etc/clue_00.png");
	background-repeat: no-repeat;
  background-position: calc(50% + 650px) 450px;
	background-size: 210px 210px;
}

.secret_01{
  background-image: url("/chiasmata/images/etc/clue_01.png");
  background-repeat: no-repeat;
  background-position: calc(50% + 600px) 750px;
	background-size: 210px 210px;
}

.secret_02{
  position: absolute;
  top: 700px;
  left: -150px;
}

.secret_03{
  position: absolute;
  top: 150px;
  left: -300px;
}

.secret_04{ /* page 150 */
  position: absolute;
  top: 400px;
  left: -170px;
}

.secret_05{ /* page 183 */
  position: absolute;
  top: 550px;
  left: -160px;
}

/* content */

body.pink .content{
  background-color: #351e36;
}

body.pink_1 .content{
  background-color: #2c1d2d;
}

body.pink_2 .content{
  background-color: #2c1d2d;
}

/* other */

body.pink #page{
  background-color: #070004;
  color: #FFF8FC;
}

body.pink_1 #page{
  background-color: #030002;
  color: #fffefe;
}

body.pink nav{
  background-color: #070004;
}

body.pink_1 nav{
  background-color: #030002;
}

body.pink #navbar{
  background-color: #0B090C;
}

body.pink_1 #navbar{
  background-color: #0C0B0C;
}

/* */

/* Spooky-style, dim and/or dark */

body.dark{
  background-color: #282828;
}

body.dark .content{
  background-color: #1b1b1b;
}

/* The Location's Funniest Home Videos */

body.video{
  background-color: #282828;
  background-image: url("/assets/images/static_light.png");
  background-repeat: repeat;
}

body.video .content{
  background-color: #1b1b1b;
}

/* Spooky, yet spookier... */

body.darker{
  background-color: #0f0f0f;
  background-image: url("/assets/images/static_dark.png");
  background-repeat: repeat;
}

body.darker .content{
  background-color: #151515;
}

/* Someone else is spooky */

body.orange{
  background-color: #5c514d;
}

body.orange .content{
  background-color: #38322f;
}

/* Other general Chiasmata page bits */

.content{
  background-color: #333333;
  font-family: 'Arial', sans-serif;
  display: block;
  margin: auto;
  margin-top:0;
  max-width: 920px;
  min-width: 600px;
  padding: 0 40px;
  overflow-x: visible;
  position: relative;
}

/* link colors */
a:link{
	color: #aaaaaa;
	text-decoration: none;
}
a:visited{
	color: #777777;
	text-decoration: none;
}
a:hover{
	color: #ffffff;
	text-decoration: none;
}
a:active{
	color: #777777;
	text-decoration: none;
}

a.current{
  color: green;
}

#page{
  background-color: #000000;
  padding: 0 25px;
  display: block;
  margin: auto;
  margin-top: 0;
  max-width: 750px;
  min-width: 550px;
  position: relative;
}

/* This is to make the things meet the bottom of the page, because margins in CSS are weird. */

.content h1:first-of-type, .content h2:first-of-type{
  padding-top:19px;
  padding-bottom: 15px;
  margin-top: 0px;
}

.content p:last-of-type, .content h2:last-of-type, .content ol:last-of-type{
  padding-bottom: 15px;
  margin-bottom: 0px;
}

#page .dialogue{
  margin-left: 56px;
}

.dialogue p{
  margin: 5px;
}

.Sam{
  color: #006cff;
}

.Aidan{
  color: #00ff90;
}

.Daniel{
  color: #ff5400;
}

.Bonnie{
  color: #ffd800;
}

.Jacqueline{
  color: #FF0000;
}

.Clara{
  color: #88ff00;
}

.Leo{
  color: #ffba00;
}

.Ghost{
  color: #b58400;
}

.Omar{
  color: #593eff;
}

.David{
  color: #ff9000;
}

.Dawn{
  color: #3041ff;
}

.Ash{
  color: #00ff00;
}

.Anders{
  color: #ff0096;
}

.green_mystery{
  color: #00ff69;
}

.orange_mystery{
  color: #FF8B00;
}

.purple_mystery{
  color: #c800ff;
}

.Robot{
  font-family: 'Courier New', Courier, monospace;
}

.other_mystery{
  text-align: right;
  font-family: 'Courier New', Courier, monospace;
}

#page .small{
  font-size: 0.5em;
}

// This is what I use to blink the cursor.

.blink_me {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#page img{
  margin: 0 -25px;
}

#page .system{
  color: #ffffff;
}

#page .Archie{
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  text-decoration: line-through;
}

#page .Computer{
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

#page h1{
  text-align: center;
  padding: 14px 0;
}

#page .center-image{
  margin: 0 auto;
  display: block;
}

.center-image{
  margin: 0 auto;
  display: block;
}

/* STUFF FOR THE BLOG SECTION */

.post-nav {
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.post-nav div {
  flex: 1 1 0;
}

.post-nav-next {
  text-align: right;
}

/* Animation stuff? */

/* Our mixin positions a copy of our text
directly on our existing text, while
also setting content to the appropriate
text set in the data-text attribute. */
@mixin glitchCopy { 
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch {
position: relative;
color: white;
font-family: 'Courier New', Courier, monospace;
/* Animation provies a slight random skew. Check bottom of doc
for more information on how to random skew. */
animation: glitch-skew 1s infinite linear alternate-reverse;

// Creates a copy before our text. 
&::before{
  // Duplicates our text with the mixin.
  @include glitchCopy;
  // Scoots text slightly to the left for the color offset.
  left: 2px;
  // Creates the color 'shadow' that happens on the glitch.
  text-shadow: -2px 0 #ff00c1;
  /* Creates an initial clip for our glitch. This works in
  a typical top,right,bottom,left fashion and creates a mask
  to only show a certain part of the glitch at a time. */
  clip: rect(44px, 450px, 56px, 0);
  /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
  with an alternating animation to keep things fresh. */
  animation: glitch-anim 5s infinite linear alternate-reverse;
}

// Creates a copy after our text. Note comments from ::before.
&::after {
  @include glitchCopy;
  left: -2px;
  text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}
}

/* Creates an animation with 20 steaps. For each step, it calculates 
a percentage for the specific step. It then generates a random clip
box to be used for the random glitch effect. Also adds a very subtle
skew to change the 'thickness' of the glitch.*/
@keyframes glitch-anim {
$steps: 20;
@for $i from 0 through $steps {
  #{percentage($i*calc(1/$steps))} {
    clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    transform: skew(calc(random(100) / 100) + deg);
  }
}
}

// Same deal, just duplicated for ultra randomness.
@keyframes glitch-anim2 {
$steps: 20;
@for $i from 0 through $steps {
  #{percentage($i*calc(1/$steps))} {
    clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    transform: skew(calc(random(100) / 100) + deg);
  }
}
}

// Does the same deal as before, but now only skews. Used for main text.
@keyframes glitch-skew {
$steps: 10;
@for $i from 0 through $steps {
  #{percentage($i*calc(1/$steps))} {
    transform: skew((random(10) - 5) + deg);
  }
}
}